import React from "react";
import { useHistory } from "react-router";

const Accueil = () => {
  let history = useHistory();
  return (
    <div class="container  text-center">
      <div class="card-header fs-1 fw-bold ">Bienvenue</div>
      <div class="card-body fs-4">
        <p class="card-text text-light">
          Ce formulaire a été crée dynamiquement à partir d'un template JSON.
          Quand on POST ce formulaire les données sont stockés dans le
          localstorage et la page de remerciement s'ouvre pour remercier
          l'utilisateur en affichant son prénom. L'input dans la page de
          remerciement permet de changer le prénom de l'utilisateur.
        </p>
        <ul class="list-group">
          <li class="list-group-item active fw-bold" aria-current="true">
            Technologies utilisées :
          </li>
          <li class="list-group-item">Bootstrap</li>
          <li class="list-group-item">JSON</li>
          <li class="list-group-item">Locale Storage</li>
          <li class="list-group-item">React-hook-form</li>
          <li class="list-group-item">React Router</li>
        </ul>
        <button
          onClick={() => history.push("/inscription")}
          className="btn mt-4 btn-primary px-5 btn-lg btn-block "
        >
          Voir
        </button>
      </div>
    </div>
  );
};

export default Accueil;
